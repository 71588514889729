import {Controller} from '@hotwired/stimulus';
import {Datepicker} from 'vanillajs-datepicker';
import de from 'vanillajs-datepicker/locales/de';
import en from 'vanillajs-datepicker/locales/en-CA';

Object.assign(Datepicker.locales, en, de);

export class DatepickerController extends Controller {

    static values = {
        openerId: String,
        maxDate: String,
        minDate: String,
        locale: String,
        minDateFor: String,
        maxDateFor: String,
        setDateFor: String,
        setDateForOffset: { type: Number, default: 0 }
    }

    static targets = [
        'input'
    ]

    connect() {
        this.config = this.loadConfig();
        // this.datepickerElement.setAttribute('type', 'text');
        this.datepickerElement.setAttribute('autocomplete', 'off');
        // register before datepicker because of event order
        this.registerDateFixer();
        this.datepicker = new Datepicker(this.datepickerElement, {
            ...this.config
        });
        this.registerCalButtons();
        this.registerRestrictors();
    }

    disconnect() {
        this.datepicker.destroy();
    }

    loadConfig() {
        return {
            language: this.hasLocaleValue ? this.mapLocale(this.localeValue) : undefined,
            minDate: this.minDateValue,
            maxDate: this.maxDateValue,
            buttonClass: 'btn',
            showOnClick: false,
            showOnFocus: false,
            autohide: true,
            orientation: 'auto right',
            todayBtn: true,
            // clearBtn: true,
            todayHighlight: true
        };
    }

    mapLocale(locale) {
        switch (locale) {
            case 'en':
                return 'en-CA';
            default:
                return locale;
        }
    }

    minDateValueChanged() {
        if (this.config && this.datepicker) {
            this.config.minDate = this.minDateValue;
            this.datepicker.setOptions(this.config)
        }
    }

    maxDateValueChanged() {
        if (this.config && this.datepicker) {
            this.config.maxDate = this.maxDateValue;
            this.datepicker.setOptions(this.config)
        }
    }

    registerCalButtons() {
        if (this.hasOpenerIdValue) {
            document.getElementById(this.openerIdValue).addEventListener('click', () => this.datepicker.show());
        }
    }

    registerRestrictors() {
        if (this.hasMinDateForValue) {
            this.updateMinDate();
            this.datepicker.element.addEventListener('changeDate', () => this.updateMinDate());
        }
        if (this.hasMaxDateForValue) {
            this.updateMaxDate();
            this.datepicker.element.addEventListener('changeDate', () => this.updateMaxDate());
        }
        if (this.hasSetDateForValue) {
            this.datepicker.element.addEventListener('changeDate', () => this.setDateFor());
        }
    }

    registerDateFixer() {
        this.datepickerElement.addEventListener('keydown', (event) => {
            const key = event.key;
            if (key === 'Tab' || key === 'Enter') {
                this.fixDate();
            }
        });
    }

    fixDate() {
        const dateMs = Datepicker.parseDate(this.datepickerElement.value, Datepicker.locales[this.datepicker.config.language].format);
        const date = dateMs ? new Date(dateMs) : undefined;
        if (date && date.getFullYear() < 100) {
            let year = date.getFullYear() + 1900
            if (year < new Date().getFullYear() - 90) {
                year += 100;
            }
            date.setFullYear(year);
        }
        this.datepicker.element.value = Datepicker.formatDate(date, Datepicker.locales[this.datepicker.config.language].format);
        this.datepicker.setDate(date);
    }

    updateMinDate() {
        const elements = this.findElements(this.minDateForValue);
        elements.forEach(e => {
            const date = this.datepicker.getDate(Datepicker.locales[this.datepicker.config.language].format);
            if (date) {
                e.setAttribute('data-datepicker-min-date-value', date)
            } else {
                if (e.hasAttribute('data-datepicker-min-date-value')) {
                    e.removeAttribute('data-datepicker-min-date-value')
                }
            }
        })
    }

    updateMaxDate() {
        const elements = this.findElements(this.maxDateForValue);
        elements.forEach(e => {
            const date = this.datepicker.getDate(Datepicker.locales[this.datepicker.config.language].format);
            if (date) {
                e.setAttribute('data-datepicker-max-date-value', date)
            } else {
                if (e.hasAttribute('data-datepicker-max-date-value')) {
                    e.removeAttribute('data-datepicker-max-date-value')
                }
            }
        })
    }

    setDateFor() {
        const date = this.datepicker.getDate();
        if (!date) {
            return;
        }
        const elements = this.findElements(this.setDateForValue);
        elements.forEach(e => {
            var offsetDate = new Date(date);
            offsetDate.setDate(offsetDate.getDate() + this.setDateForOffsetValue);
            e.datepicker.setDate(offsetDate);
        })
    }

    findElements(pattern) {
        if (!pattern) {
            return [];
        }
        // the form is a performance optimization. In general we don't expect to connect inputs across forms.
        const form = this.datepickerElement.closest('form');
        let elements;
        let i;
        switch (pattern) {
            case ':next':
                elements = Array.prototype.slice.call((form || document).querySelectorAll('input.date, input.date_picker'));
                i = elements.indexOf(this.datepickerElement);
                return elements[i + 1] ? [elements[i + 1]] : []
            case ':previous':
                elements = Array.prototype.slice.call((form || document).querySelectorAll('input.date, input.date_picker'));
                i = elements.indexOf(this.datepickerElement);
                return elements[i - 1] ? [elements[i - 1]] : []
            default:
                return (form || document).querySelectorAll(pattern)
        }

    }

    get datepickerElement() {
        return (this.hasInputTarget && this.inputTarget) || this.element
    }

}
