.select2-container .select2-choices {
  font-family: inherit;
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: none;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background: none;
}

.select2-container .select2-choices .select2-search-field input, .select2-container .select2-choice, .select2-container .select2-choices {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUva3Vyc28vYXBwcy9rdXJzby9yZWxlYXNlcy8yMDI1MDMyMTE1NTEwMy9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3NlbGVjdDIiLCJzb3VyY2VzIjpbInNlbGVjdDIta3Vyc28uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJOzs7QUFHSjtFQUNJOzs7QUFHSjtBQUFBO0VBRUk7OztBQUdKO0VBQ0k7RUFDQTtFQUNBOzs7QUFHSjtFQUNJO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5zZWxlY3QyLWNvbnRhaW5lciAuc2VsZWN0Mi1jaG9pY2VzIHtcbiAgICBmb250LWZhbWlseTogaW5oZXJpdDtcbn1cblxuLnNlbGVjdDItY29udGFpbmVyLW11bHRpIC5zZWxlY3QyLWNob2ljZXMgLnNlbGVjdDItc2VhcmNoLWNob2ljZS1mb2N1cyB7XG4gICAgYmFja2dyb3VuZDogbm9uZTtcbn1cblxuLnNlbGVjdDItZHJvcGRvd24tb3Blbi5zZWxlY3QyLWRyb3AtYWJvdmUgLnNlbGVjdDItY2hvaWNlLFxuLnNlbGVjdDItZHJvcGRvd24tb3Blbi5zZWxlY3QyLWRyb3AtYWJvdmUgLnNlbGVjdDItY2hvaWNlcyB7XG4gICAgYmFja2dyb3VuZDogbm9uZTtcbn1cblxuLnNlbGVjdDItY29udGFpbmVyIC5zZWxlY3QyLWNob2ljZXMgLnNlbGVjdDItc2VhcmNoLWZpZWxkIGlucHV0LCAuc2VsZWN0Mi1jb250YWluZXIgLnNlbGVjdDItY2hvaWNlLCAuc2VsZWN0Mi1jb250YWluZXIgLnNlbGVjdDItY2hvaWNlcyB7XG4gICAgLXdlYmtpdC1ib3JkZXItcmFkaXVzOiAwICFpbXBvcnRhbnQ7XG4gICAgLW1vei1ib3JkZXItcmFkaXVzOiAwICFpbXBvcnRhbnQ7XG4gICAgYm9yZGVyLXJhZGl1czogMCAhaW1wb3J0YW50O1xufVxuXG4uc2VsZWN0Mi1jb250YWluZXItbXVsdGkgLnNlbGVjdDItY2hvaWNlcyAuc2VsZWN0Mi1zZWFyY2gtY2hvaWNlIHtcbiAgICAtd2Via2l0LWJvcmRlci1yYWRpdXM6IDAgIWltcG9ydGFudDtcbiAgICAtbW96LWJvcmRlci1yYWRpdXM6IDAgIWltcG9ydGFudDtcbiAgICBib3JkZXItcmFkaXVzOiAwICFpbXBvcnRhbnQ7XG59XG4iXX0= */