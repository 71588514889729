import { loadStripe } from '@stripe/stripe-js/pure';
import { Controller } from '@hotwired/stimulus';
import {alert} from 'bootbox';

export class StripePaymentController extends Controller {

    static values = {
        pubkey: String,
        account: String,
        clientSecret: String,
        returnUrl: String,
        payerName: String,
        payerEmail: String,
        payerAddress: String,
        payerPostalCode: String,
        payerCity: String,
        payerCountry: String,
    }

    static targets = ['element', 'message', 'form', 'submit']

    stripe;
    elements;

    connect() {
        this.connectAsync().then()
    }

    async connectAsync() {
        this.stripe = await loadStripe(this.pubkeyValue, { stripeAccount: this.accountValue, locale: document.documentElement.lang });
        const appearance = {
            theme: 'stripe',
        };
        this.elements = this.stripe.elements({ appearance, clientSecret: this.clientSecretValue });
        const paymentElement = this.elements.create('payment', {
            defaultValues: {
                billingDetails: {
                    name: this.payerNameValue || undefined,
                    email: this.payerEmailValue || undefined,
                    address: {
                        line1: this.payerAddressValue || undefined,
                        country: this.payerCountryValue || undefined,
                        postal_code: this.payerPostalCodeValue || undefined,
                        city: this.payerCityValue || undefined
                    }
                }
            }
        });
        paymentElement.mount(this.elementTarget);

        this.formTarget.addEventListener('submit', this.handleSubmit.bind(this));
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setLoading(true);

        const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: this.returnUrlValue,
            },
        });

        console.log(error)

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            alert(error.message);
        } else {
            alert('An unexpected error occurred.');
        }

        this.setLoading(false);
    }

    setLoading(isLoading) {
        this.submitTarget.disabled = !!isLoading;
    }

}

