/** Bootstrap fix **/
@media print {
    .col-md-1,.col-md-2,.col-md-3,.col-md-4,
    .col-md-5,.col-md-6,.col-md-7,.col-md-8,
    .col-md-9,.col-md-10,.col-md-11,.col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8%;
    }
    .col-md-2 {
        width: 16%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-4 {
        width: 33%;
    }
    .col-md-5 {
        width: 42%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-7 {
        width: 58%;
    }
    .col-md-8 {
        width: 66%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-10 {
        width: 83%;
    }
    .col-md-11 {
        width: 92%;
    }
    .col-md-12 {
        width: 100%;
    }
}
